.ordermodalmain{
    width: 80%;
    position: fixed;
    top: 10%;
    height: fit-content;
    overflow-y: scroll;
    transform: translateX(-10%);
    right: 0%;
    background: #ffffff;
}

.overlayordermodal{
    background: #000;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    opacity: 0.7;
}

/* .ordermodalmain div table.table-responsive thead th tr{
    max-width: unset !important;
} */

table td,
table th {
  max-width: unset !important;
}

@media (max-width:767px) {
    .ordermodalmain {
        width: 98%;
        top: 5%;
        transform: translateX(-1%);
    }
}
  