.heading {
    border-bottom: 2px solid black;
}

.unorderList li {
    margin-left: 15px;
    position: relative;
    padding-left: 5px;
}

.unorderList {
    margin: 0;
}

.unorderList li {
    list-style: none;
    cursor: grab;
}

.unorderList li::before {
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #000;
    top: 5px;
    bottom: -12px;
    left: -10px;
}

.unorderList>li:first-child::before {
    top: 12px;
}

.unorderList li:not(:first-child):last-child::before {
    display: none;
}

.unorderList li:only-child::before {
    display: list-item;
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #000;
    top: 5px;
    bottom: 7px;
    height: 100%;
    left: -10px;
}

.unorderList li::after {
    content: " ";
    position: absolute;
    left: -10px;
    width: 10px;
    height: 1px;
    background-color: #000;
    top: 12px;
}

.buttonLikeDiv {
    background-color: #105293;
    border: 1px solid black;
    color: white;
    font-size: 13px;
    padding: 1px;
    width: 125px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}