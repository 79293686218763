* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover {
  text-decoration: none;
}

.tiny_size {
  font-size: 11px;
}
.small_text{
  font-size: 11px;
  text-decoration: underline;
  margin-left: 5px;
}
.bold_mak{
  font-weight: 800;
}
.loader-wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
}

.loader {
  border: 16px solid #e3e3e3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.selectcategory
{
  border: 1px solid #d1d3e2;
  padding: 4px;
  border-radius: 5px;
  cursor: pointer;
}
.subcategory
{
  border: 1px solid #d1d3e2;
}
.subcategory li{
  cursor: pointer;
}
.subcategory ul
{
  list-style: none;
}
input[type="search"],select,input[type="date"]
{
  border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    margin-left: 3px;
    margin-bottom: 10px;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}