.button{
    border: none;
    outline: none;
    background-color: blue;
    color: white;
    padding: 5px 10px;
    border-radius: 6px;
    font-weight: 500;
}

