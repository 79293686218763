.pop-up {
  position: absolute;
  top: 0%;
  /* left: 0%; */
  /* transform: translate(-50%, -50%); */
  /* transform: translate(-35%, -105%); */
  /* transform: translate(-27%, -95%); */
  transform: translate(-75%, -67%);
  background-color: white;
  /* padding: 20px; */
  border-radius: 10px;
  box-shadow: 0px 0px 10px #333;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pop-up-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productListing_mobile {
  display: none;
}

.productListing_desktop {
  display: block;
}

.inputs {
  display: none;
}

.cardBodyHeight {
  min-height: 80vh;
}

.searchDiv {
  border: 1px solid #0094ff;
}

.searchDiv select {
  font-size: 13px;
  font-weight: 600;
  background: #0094ff;
  color: white;
}

.searchDiv input {
  border: none;
  outline: none;
  font-size: 12px;

}

.switch {
  position: relative;
  display: inline-block;
  width: 40px !important;
  height: 22px !important;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}



.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px !important;
  width: 16px !important;
  left: 3px !important;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(17px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




@media screen and (max-width: 768px) {
  .productListing_mobile {
    display: block;
  }

  .productListing_desktop {
    display: none;
  }

  .importingSection {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 15px;
  }

  .inputs_desktop {
    display: none;
  }

  .inputs {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .pop-up {
    right: 12px;
    width: 187px;
    padding: 6px 0px;
  }

  .multiselectdivision {
    border: 1px solid lightgray;
  }

  .multiselectdivision div ul li {
    list-style: none;

  }
}

/* ------------------------------------------------- */


.inputMain img {
  display: none;
}



.btnvar {
  height: 31px !important;
}

.filterselection {
  /* border: 1px solid red; */
  border: 1px solid grey;
  border-radius: 10px;
  width: 40%;
  margin-top: 10px;
  background-color: white;
  min-height: 250px;
  position: absolute;
  z-index: 10;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .inputMain {
    padding: 3px 5px;
    border-radius: 10px;
    width: 48%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* background-color: #0094ff; */
    background-color: inherit;
    border: 1px solid black;
    margin: 2px;

  }

  .inputTypeDate {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    margin-left: 3px;
    border: none;
    /* width: 29px; */
    font-size: 13px;

    background: blue;
  }

  .selector {
    margin-top: 5px;
    border: 1px solid black;
    outline: none;
    font-size: 13px;
    width: 48%;
    margin-bottom: 0px !important;
  }

  .date {
    color: black;
    font-size: 13px;
  }

  .inputMain input {
    background-color: inherit;
    border: none;
    outline: none;
    width: 90%;
    /* color: white; */
    color: black;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }

  .inputMain img {
    width: 11%;
    display: block;
  }

  .inputMain input::placeholder {
    /* color: white; */
    color: black;
    font-size: 11px;
  }

}

.inputMain img {
  display: none;
}

@media screen and (max-width: 768px) {
  .inputMain {
    padding: 3px 5px;
    border-radius: 10px;
    width: 48%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* background-color: #0094ff; */
    background-color: inherit;
    border: 1px solid black;
    margin: 2px;

  }

  .inputTypeDate {
    border: 1px solid #aaa;
    border-radius: 3px;
    padding: 5px;
    background-color: transparent;
    margin-left: 3px;
    border: none;
    /* width: 29px; */
    font-size: 13px;

    background: blue;
  }

  .selector {
    margin-top: 5px;
    border: 1px solid black;
    outline: none;
    font-size: 12px;
    width: 48%;
    margin-bottom: 0px !important;
  }

  .date {
    color: black;
    font-size: 13px;
  }

  .inputMain input {
    background-color: inherit;
    border: none;
    outline: none;
    width: 90%;
    /* color: white; */
    color: black;
    margin-bottom: 0px !important;
    padding: 0px !important;
  }

  .inputMain img {
    width: 10%;
    display: block;
  }

  .inputMain input::placeholder {
    /* color: white; */
    color: black;
    font-size: 11px;
  }

}

@media screen and (max-width: 321px) {
  .selector {
    font-size: 10px;
  }
}

/* --------------------------------------- */
table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 15px;
  width: 100px !important;
  max-width: 100px;
  text-align: center;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th,
tfoot td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background: #001ad5;
  color: white;
}

.page_link_item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  border: 1px solid #dddfeb;
}